#container_technologies{
    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#techList{
    display:flex;
    flex-wrap: wrap;
}
.techHeaders{
    color:#64FFDA;
}
ul{
    list-style-type: ' - ';
}