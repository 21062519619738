#container_experiences{
    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#experiences_container{
    width:90%;
    height:auto;
    background-color:#112240;
    border-radius: 10%;
    display: none;
    padding:20px;
}

#selected_list_item{
    color:#64FFDA;
}

.list_item{
    color:rgb(255, 255, 255);
    font-size:20px;
    padding-top:5px;
    padding-bottom: 5px;
}

.list_item :hover{
    cursor:pointer;
}


#experience_header{
    color:#64FFDA;
    font-size:25px;
    margin-top:0px;
    margin-bottom: 5px;
    text-decoration: none;
}
#Rightcontainer{
    margin-left:5%;
    margin-right: 5%;
}
#experience_periods{

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #experiences_container{
        display:grid;
        grid-template-columns:300px auto;
    }
    #experience_box{
        width:95%;
        height:300px;
    }
    .experience_header{font-size: 30px;}
}