.introEffect{
    animation: intro 2s;
    -webkit-animation: intro 2s;
    -moz-animation: intro 2s;
    -o-animation: intro 2s;
    -ms-animation: intro 2s;
}
.fadeInEffect{
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
    opacity: 1;
}
.fadeOutEffect{
    animation: fadeOut 2s;
    -webkit-animation: fadeOut 2s;
    -moz-animation: fadeOut 2s;
    -o-animation: fadeOut 2s;
    -ms-animation: fadeOut 2s;
    opacity: 0;
}

@keyframes intro{
    from {
        opacity: 0;
        right: 100%;
        left:0%;
    }
    to{
        opacity: 1;
        right: 0%;
        left:100%;
    }
}

@keyframes fadeIn{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut{
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}