#container_open_sap_certificates {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

table {
    width: 80%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
    background-color: #112240;
}

th, td {
    padding: 12px 15px;
    border: 2px solid black;
    text-align: center;
}

th {
    background-color: #0A192F;
    color: #CCD6F6;
}

td {
    color: #CCD6F6;
}

.CertBoxes {
    border-radius: 10%;
    background-color: #112240;
    text-align: center;
}

.certHeaders {
    color: #64FFDA;
    font-size: 25px;
    text-decoration: none;
    max-width: 100px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    table {
        width: 60%;
    }
    .certHeaders {
        font-size: 30px;
    }
}
