#container_projects{
    min-height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ProjectBoxes{
    border: 2px solid black;
    border-radius:  10% ;
    padding: 20px;
    width: 80%;
    height: auto;
    margin:5px;
    background-color: #112240;
}
.projHeader{
    color:#64FFDA;
    font-size: 25px;
    margin-top:0px;
    margin-bottom: 15px;
    padding-top:0px;
}
.proj_footer{
    font-size: 17px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #projects{
        display:flex;
        flex-wrap: wrap;
    }
    .ProjectBoxes{
        border: 2px solid black;
        border-radius:  10% ;
        background: white;
        padding: 20px;
        width: 29%;
        height: 360px;
        margin:5px;
        background-color: #112240;
    }
    .projHeader{font-size: 30px}
}