#container_degrees{
    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.DegreeBoxes{
    border: 2px solid black;
    border-radius:  10%;
    width: 80%;
    height: auto;
    background-color: #112240;
    padding:20px;
    margin:5px;
}
.degHeader{
    color:#64FFDA;
    font-size: 25px;
    margin:0;
}
.degrees_link{
    text-decoration: none;
    color:#8892B0;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #degrees{
        display:flex;
        flex-wrap: wrap;
    }
    .DegreeBoxes{
        padding: 20px;
        width: 400px;
        height: 150px;
        margin:5px;
    }
    .degHeader{
        font-size: 30px;
        margin-top:0;
        margin-bottom: 10px;
        padding-top:0;
    }
}