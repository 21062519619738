#container_intro{
    color:#CCD6F6;
    width: 100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#introName{
    font-size:60px;
    margin-bottom: 5px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #introName{
        font-size: 80px;
    }
}