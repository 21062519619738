body{
    background-color: #0A192F;
}
p, li{
    color:#8892B0;
}
*{
    scroll-behavior: smooth;
    font-family: "Kollektif", sans-serif;
}
.important{
    color:#64FFDA;
}

/*
high text - 64FFDA
headers- CCD6F6
text - 8892B0
boxes - 112240
*/