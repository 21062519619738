#fullContainer{
    margin-left:5%;
    margin-right:5%;
    width:100%;
}
#Rightcontainer{

}
p, li,a{
    font-size:20px;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #Rightcontainer{
        margin-left:5%;
        margin-right: 5%;
    }
}