#navbar_list {
    list-style-type: none;
    line-height: 20px;
    display: none;
    background-color: #112240;
    padding: 10px 20px; /* Adjusted padding */
    margin: 0; /* Remove default margin */
}

#navbar_container {
    position: fixed; /* Fixed position to stay at the top */
    top: 0; /* Align to the top */
    width: 100%; /* Full width */
    background-color: #0A192F; /* Background color for the navbar */
    z-index: 1000; /* High z-index to stay on top of other elements */
    display: flex;
    justify-content: space-between; /* Space between elements */
    align-items: left; /* Center items vertically */
    padding: 10px 20px; /* Padding around the container */
}

.navbar_header{
    border-radius: 50px;
    color: #64FFDA;
    font-size: 30px;
    margin: 0 10px; /* Spacing between navbar items */
}
.navbar_items {
    border-radius: 50px;
    color: #CCD6F6;
    font-size: 20px;
    margin: 0 10px; /* Spacing between navbar items */
}

.navbar_items:hover {
    color: #64FFDA;
}

.icons {
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.icons:hover {
    color: #64FFDA;
}

#open_nav_bar_button {
    position: relative;
    display: block;
    margin-left: auto;
    color: white;
    background-color: #0A192F;
    font-size: 25px;
    cursor: pointer; /* Add cursor pointer for better UX */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #open_nav_bar_button, #close_nav_bar_button {
        display: none;
    }

    #navbar_list {
        display: flex; /* Flex display for horizontal alignment */
        background-color: #0A192F;
        padding: 0;
        margin: 0; /* Remove default margin */
    }

    #navbar_container {
        height: auto;
        width: 100%; /* Full width for larger devices */
        background-color: #0A192F; /* Ensure background color consistency */
    }
}
